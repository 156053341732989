<template>
  <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      :fullscreen="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :modal-append-to-body="true"
      :append-to-body="true"
      :before-close="handleClose">
    <el-form size="small" :model="inputForm" class="searchForm" :rules="rules" ref="inputForm" label-width="120px">
      <div class="minTitle">宣传报道基本信息</div>
      <el-form-item class="dateBox" label="报道名称" prop="reportName">
        <el-input v-model.trim="inputForm.reportName" clearable maxlength="60" :disabled="inpuDisabled"
                  placeholder="请输入报道名称(限60字)"></el-input>
      </el-form-item>
      <el-form-item class="dateBox" label="报道媒体" prop="reportMedia">
        <el-input v-model.trim="inputForm.reportMedia" clearable maxlength="50" :disabled="inpuDisabled"
                  placeholder="请输入报道媒体(限50字)"></el-input>
      </el-form-item>
      <el-form-item label="报道日期" prop="reportDate">
        <el-date-picker :disabled="inpuDisabled"
                        v-model="inputForm.reportDate"
                        type="date"
                        clearable
                        format="yyyy-MM-dd"
                        style="width: 100%;"
                        value-format="yyyy-MM-dd"
                        placeholder="选择报道日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="报道关键字" prop="keyword">
        <el-input v-model.trim="inputForm.keyword" clearable maxlength="50" :disabled="inpuDisabled"
                  placeholder="请输入报道关键字(限50字)"></el-input>
      </el-form-item>
      <div class="minTitle">宣传报道内容</div>
      <el-form-item class="dateBox" label="宣传链接" prop="publicityLink">
        <el-input v-show="!inpuDisabled" v-model.trim="inputForm.publicityLink" clearable :disabled="inpuDisabled"
                  placeholder="请输入宣传链接"></el-input>
        <div v-show="inpuDisabled" class="flex_b_t">
          <el-link @click="gotoLink()" type="primary" style="padding-right: 20px">{{
              inputForm.publicityLink
            }}
          </el-link>
          <el-button icon="el-icon-document-copy" @click="copyText"></el-button>
        </div>
      </el-form-item>
      <el-form-item class="dateBox" style="position: relative" label="宣传内容" prop="publicityContent">
        <Editor v-show="!inpuDisabled" ref="Editor" :editortext="inputForm.publicityContent"
                @changeHtml="contentGetEditor"></Editor>
        <div v-show="inpuDisabled" v-html="inputForm.publicityContent" class="publicityContent"></div>
      </el-form-item>
    </el-form>
    <div slot="footer" class="text_center">
      <template v-if="!inpuDisabled">
        <el-button size="small" @click="handleClose()">取消</el-button>
        <el-button size="small" type="primary" @click="suresData()" v-no-more-click>保存</el-button>
      </template>
      <el-button v-else size="small" @click="handleClose()">关闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
import Editor from "@/components/editor/editor.vue";

export default {
  name: 'disseminateAdd',
  components: {Editor},
  data() {
    return {
      title: '',
      dialogVisible: false,
      inputForm: {
        reportName: '',
        reportMedia: '',
        reportDate: '',
        keyword: '',
        publicityLink: '',
        publicityContent: '',
      },
      rules: {
        reportName: [
          {required: true, message: '请输入报道名称', trigger: 'blur'}
        ],
        // reportMedia: [
        //   {required: true, message: '请输入报道媒体', trigger: 'blur'}
        // ],
        reportDate: [
          {required: true, message: '请选择报道日期', trigger: 'change'}
        ],
      },
      inpuDisabled: false,
      selectData: {},
    }
  },

  methods: {
    init(num, selectData) {
      this.inpuDisabled = false
      if (num == 0) {
        this.title = '新增宣传报道专题库'
      } else {
        if (num == 1) {
          this.title = '查看宣传报道专题库'
          this.inpuDisabled = true
        } else {
          this.title = '修改宣传报道专题库'
        }
        this.selectData = selectData
        this.$axios(this.api.original.mediathematiclibraryGetById + this.selectData.id, {}, "get").then((data) => {
          if (data && data.status) {
            this.inputForm = data.data
          }
        });
      }
      this.dialogVisible = true
    },

    // 获取富文本数据(活动内容)
    contentGetEditor(data) {
      if (data === '<p><br></p>') {
        data = '';
      }
      this.inputForm.publicityContent = data;
    },

    //复制链接
    copyText() {
      const input = document.createElement('input');
      input.value = this.inputForm.publicityLink; // 设置要复制的文本
      document.body.appendChild(input); // 添加input元素
      input.select(); // 选中文本
      document.execCommand('copy'); // 执行复制操作
      document.body.removeChild(input); // 移除input元素
      this.$message.success('复制成功')
    },

    //去新的页面
    gotoLink() {
      if (this.inputForm.publicityLink.includes("https://")) {
        var a = document.createElement("a");
        a.setAttribute("href", this.inputForm.publicityLink);
        a.setAttribute("target", "_blank");
        a.click();
      } else {
        let path = window.location.protocol + '//' + this.inputForm.publicityLink
        window.open(path, "_blank")
      }
    },

    handleClose() {
      this.$refs.inputForm.resetFields()
      this.inputForm = {
        reportName: '',
        reportMedia: '',
        reportDate: '',
        keyword: '',
        publicityLink: '',
        publicityContent: '',
      }
      this.dialogVisible = false
    },

    suresData() {
      this.$refs.inputForm.validate((valid) => {
        if (valid) {
          if (this.inputForm.id) {
            this.$axios(this.api.original.mediathematiclibraryUpdateById, {
              ...this.inputForm
            }, "put").then((data) => {
              if (data && data.status) {
                this.$message.success('修改成功')
                this.$emit('refreshList')
                this.handleClose()
              } else {
                this.$message.error(data.msg)
              }
            });
          } else {
            this.$axios(this.api.original.mediathematiclibrarySave, {
              ...this.inputForm
            }, "post").then((data) => {
              if (data && data.status) {
                this.$message.success('保存成功')
                this.$emit('refreshList')
                this.handleClose()
              } else {
                this.$message.error(data.msg)
              }
            });
          }
        }
      })
    },
  },
}
</script>

<style scoped>
.searchForm {
  .el-form-item--small.el-form-item {
    display: inline-block;
    width: 50%;
  }

  .dateBox.el-form-item--small.el-form-item {
    display: inline-block;
    width: 100%;
  }
}

.minTitle {
  font-weight: bold;
  font-size: 14px;
  padding-bottom: 20px;
}

.publicityContent {
  iframe {
    width: 800px !important;
    height: 500px !important;
  }
}
</style>