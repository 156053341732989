<template>
  <div class="page" id="reorganize">
    <el-form
        @keyup.enter.native="getDataList(1,1)"
        class="query-form" :inline="true" size="small" ref="inputForm" :model="inputForm" label-width="100px">
      <el-form-item label="报道名称" prop="reportName">
        <el-input v-model.trim="inputForm.reportName" clearable maxlength="20"
                  placeholder="请输入报道名称(限20字)"></el-input>
      </el-form-item>
      <el-form-item label="报道媒体" prop="reportMedia">
        <el-input v-model.trim="inputForm.reportMedia" clearable maxlength="20"
                  placeholder="请输入报道媒体(限20字)"></el-input>
      </el-form-item>
      <el-form-item label="报道关键字" prop="keyword">
        <el-input v-model.trim="inputForm.keyword" @keyup.enter.native="getDataList(1,1)" clearable maxlength="20"
                  placeholder="请输入报道关键字(限20字)"></el-input>
      </el-form-item>
      <el-form-item label="报道日期" prop="reportDate">
        <el-date-picker
            v-model="inputForm.reportDate"
            format="yyyy-MM-dd"
            style="width: 100%;"
            value-format="yyyy-MM-dd"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label-width="0">
        <el-button type="primary" icon="el-icon-search" @click="getDataList(1,1)">查询</el-button>
        <el-button icon="el-icon-refresh-right" @click="resetting()">重置</el-button>
      </el-form-item>
      <div class="f_r text_right">
        <el-button icon='el-icon-plus'
                   type="primary"
                   size="small"
                   v-show="hasPermissionButton(`dyyg:manage:disseminate:add`)"
                   @click="addData(0)">新增
        </el-button>
        <el-button type="danger"
                   size="small"
                   icon="el-icon-delete"
                   v-show="hasPermissionButton(`dyyg:manage:disseminate:batchDelete`)"
                   @click="deleteData()">批量删除
        </el-button>
      </div>
    </el-form>
    <div class="bg-white">
      <div class="text_right">
        <el-upload class="i_block m_r1 m_l1"
                   ref="upload"
                   action="action" :show-file-list="false"
                   :http-request="getImportData"
                   :before-upload="beforeUpload">
          <el-button size="small"
                     icon="el-icon-upload2"
                     v-show="hasPermissionButton(`dyyg:manage:disseminate:import`)">批量导入
          </el-button>
        </el-upload>
        <el-button icon="el-icon-download"
                   size="small"
                   v-show="hasPermissionButton(`dyyg:manage:disseminate:download`)"
                   @click="importTemplate()">导入模板下载
        </el-button>
        <el-button size="small" plain icon="el-icon-setting" @click="setTb"></el-button>
      </div>
      <el-table
          :data="dataList"
          size="small"
          :row-key="'id'"
          @selection-change="selectionChangeHandle"
          v-loading="loading"
          ref="multipleTable"
          height="calc(100vh - 380px)"
          class="table"
      >
        <el-table-column :reserve-selection="true" type="selection" width="50"/>
        <el-table-column label="序号" type="index" width="50"></el-table-column>
        <el-table-column v-for="item in tableTitleList" :key="item.id" :prop="item.fieldEname" :label="item.fieldName"
                         :width="item.fieldName == '报道名称' ? 260 : item.fieldName == '最新修改时间' ? 150 : ''"
                         :show-overflow-tooltip="!item.fieldName == '报道名称'">
          <template slot-scope="scope">
            <div>{{ scope.row[item.fieldEname] }}</div>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="180">
          <template slot-scope="scope">
            <el-button style="margin-bottom: 10px" size="mini" type="text"
                       @click="resource(scope.row)"
                       v-show="hasPermissionButton(`dyyg:manage:disseminate:resource`)">资源管理
            </el-button>
            <el-button style="margin-bottom: 10px" size="mini" type="text"
                       @click="addData(1, scope.row)"
                       v-show="hasPermissionButton(`dyyg:manage:disseminate:detail`)">详情
            </el-button>
            <el-button style="margin-bottom: 10px" size="mini" type="text"
                       @click="addData(2, scope.row)"
                       v-show="hasPermissionButton(`dyyg:manage:disseminate:edit`)">修改
            </el-button>
            <el-button style="margin-bottom: 10px" size="mini" type="text"
                       @click="deleteData(scope.row)"
                       v-show="hasPermissionButton(`dyyg:manage:disseminate:delete`)">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="text_center">
        <el-pagination
            @size-change="sizeChangeHandle"
            @current-change="currentChangeHandle"
            :current-page="inputForm.current"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="inputForm.size"
            :total="total"
            background
            layout="total, sizes, prev, pager, next, jumper"
        >
        </el-pagination>
      </div>
    </div>
    <ImportData ref="importData" @refreshDataList="getDataList('',1)"></ImportData>
    <disseminate-add ref="disseminateAdd" @refreshList="getDataList('',1)"></disseminate-add>
    <coll-list ref="collList" @refresh="getDataList()"></coll-list>
    <!--列表设置拖拽排序弹窗-->
    <DraggablePop :fieldType="fieldType" :moduleId="id" :setShow="setShow" @getTbList="getTbList"></DraggablePop>
  </div>
</template>

<script>
import ImportData from "@/views/modules/dyyg/libraryManagement/importData.vue";
import DisseminateAdd from "@/views/modules/dyyg/libraryManagement/disseminateAdd.vue";
import CollList from "@/views/modules/dyyg/libraryManagement/collList.vue";
import DraggablePop from "@/components/draggable/draggablePop2.vue";

export default {
  name: "disseminate",
  components: {CollList, DisseminateAdd, ImportData, DraggablePop},
  data() {
    return {
      inputForm: {
        reportName: '',
        reportMedia: '',
        keyword: '',
        reportDate: [],
        reportStartDate: '',
        reportEndDate: '',
        current: 1,
        size: 10,
      },
      total: 0,
      dataList: [],
      dataListSelect: [],
      loading: false,

      id: '',
      setShow: false, // 是否显示列表设置拖拽排序弹窗
      tableTitleList: [],
      fieldType: 2,
    }
  },

  mounted() {
    let homeSetOpt = JSON.parse(sessionStorage.getItem('homeSetOpt'))
    if (homeSetOpt) {
      this.$refs.collList.init(1, '', '', homeSetOpt, 'disseminate')
      // sessionStorage.removeItem('homeSetOpt')
    }
    this.getDataList()
  },

  methods: {
    getDataList(type, dividePage) {
      if (type == 1) {
        this.inputForm.current = 1
      }
      if (dividePage) {
        this.$refs.multipleTable.clearSelection()
      }
      if (this.inputForm.reportDate && this.inputForm.reportDate.length > 0) {
        this.inputForm.reportStartDate = this.inputForm.reportDate[0]
        this.inputForm.reportEndDate = this.inputForm.reportDate[1]
      } else {
        this.inputForm.reportStartDate = ''
        this.inputForm.reportEndDate = ''
      }
      let searchData = JSON.parse(JSON.stringify(this.inputForm))
      this.$delete(searchData, 'reportDate')
      this.loading = true
      this.$axios(this.api.original.mediathematiclibraryList, {
        ...searchData
      }, "get").then((data) => {
        if (data && data.status) {
          this.loading = false
          this.dataList = data.data.records
          if (this.dataList && this.dataList.length == 0 && this.inputForm.current > 1) {
            this.inputForm.current -= 1
            this.getDataList()
          }
          this.total = parseInt(data.data.total)
        } else {
          this.$message.error('查询失败')
        }
      });
    },

    // 设置
    setTb() {
      this.setShow = true;
    },

    // 接收子组件（排序弹窗）传过来的值
    getTbList(data, state) {
      this.tableTitleList = data
      this.setShow = state;
    },

    resetting() {
      this.$refs.inputForm.resetFields();
      this.getDataList(1, 1)
    },

    //资源管理
    resource(row) {
      this.$refs.collList.init(1, row, this.inputForm, '', 'disseminate')
    },

    // 0新增 1详情 2修改
    addData(num, row) {
      this.$refs.disseminateAdd.init(num, row)
    },

    //删除
    deleteData(row) {
      if (!row && !this.dataListSelect.length) {
        this.$message.warning('请至少选择一条数据')
        return
      }
      let ids
      if (row) {
        ids = [row.id]
      } else {
        ids = this.dataListSelect.map(item => {
          return item.id
        })
      }
      this.$confirm(`确定删除所选专题库吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios(this.api.original.mediathematiclibraryRemoveById, {ids}, 'delete').then((res) => {
          if (res.status) {
            this.$message.success('删除成功')
            this.getDataList('', 1)
          } else {
            this.$message.error(res.msg);
          }
        })
      })
    },

    //导入
    getImportData(param) {
      this.$refs.importData.init(param, this.api.original.mediathematiclibraryExcelImport)
      this.$dictUtils.refreshDictList()
    },

    beforeUpload(file) { // 上传文件之前钩子
      const type = file.name.split('.')[1]
      if (type !== 'xls' && type !== 'xlsx') {
        this.$message({type: 'error', message: '只支持xls、xlsx文件格式！'})
        return false
      }
    },

    // 导入模板下载
    importTemplate() {
      this.exportExcel(this.api.original.mediathematiclibraryExportModel, {}, '宣传报道专题库模板', 'get')
    },

    //表格勾选数据
    selectionChangeHandle(val) {
      this.dataListSelect = val
    },

    // 展览每页数
    sizeChangeHandle(val) {
      this.inputForm.size = val;
      this.getDataList(1, 1)
    },
    // 展览当前页
    currentChangeHandle(val) {
      this.inputForm.current = val;
      this.getDataList('', '');
    },
  },
}
</script>

<style scoped>
</style>
